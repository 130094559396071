import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import UploadButton from '../../components/BulkUpload/UploadButton/UploadButton';
import { Table } from 'antd';

const ReportViewer = () => {
    const location = useLocation();
    const insights = location.state?.insights;
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/');
    };

    if (!insights) {
        return (
            <div className='flex flex-col justify-center items-center h-screen gap-4'>
                <p>No insights available. Please click below to generate insights</p>
                <UploadButton label={"Generate Insights"} onClick={handleClick} />
            </div>
        );
    }

    const dataSource = insights.business_areas.flatMap((area) => {
        // Find corresponding pain points and ideas for the business area
        const painPointsAndIdeas = insights.pain_points_and_ideas.find(painPoint => painPoint.id === area.id);

        // Create rows for the roles within the business area
        const roles = insights.roles
            .filter(role => role.id === area.id)
            .map((role, idx) => {
                // Find the corresponding jobs to be done for this role
                const jobDetails = insights.role_specific_insights.find(insight => insight.id === role.id);
                return {
                    key: `${area.id}-role-${idx}`,
                    role_title: (
                        <div className='tracking-wide'>

                        {role.role_title}
                        </div>
                    ),
                    role_description: (
                        <div className='flex items-start justify-start font-outfit tracking-wide'>
                            {role.role_description}
                        </div>
                    ),
                    jobs_to_be_done: jobDetails ? (
                        <ul className='list-disc list-inside'>
                            {jobDetails.jobs_to_be_done.map((job, i) => (
                                <li key={i} className='tracking-wide'>{job}</li>
                            ))}
                        </ul>
                    ) : null,
                    pain_points: painPointsAndIdeas?.pain_points ? (
                        <ul className='list-disc list-inside'>
                            {painPointsAndIdeas.pain_points.map((point, i) => (
                                <li key={i} className='tracking-wide'>{point}</li>
                            ))}
                        </ul>
                    ) : null,
                    ideas: painPointsAndIdeas?.ideas ? (
                        <ul className='list-disc list-inside'>
                            {painPointsAndIdeas.ideas.map((idea, i) => (
                                <li key={i} className='tracking-wide'>{idea}</li>
                            ))}
                        </ul>
                    ) : null,
                };
            });

        // Create a row for the business area
        const areaRow = {
            key: `area-${area.id}`,
            role_title: (
                <div className='flex flex-col tracking-wide font-outfit'>
                    <span className='font-bold'>
                        {area.business_area_title}
                    </span>
                    <span className='font-normal italic'>
                        {area.business_area_description}
                    </span>
                </div>
            ),
            role_description: '',
            jobs_to_be_done: '',
            pain_points: '',
            ideas: '',
            isArea: true
        };

        return [areaRow, ...roles];
    });

const columns = [
        {
            title: 'Role',
            dataIndex: 'role_title',
            key: 'role_title',
            render: (text, record) => {
                // Span the business area title and description across all columns
                if (record.isArea) {
                    return {
                        children: <strong>{text}</strong>,
                        props: { colSpan: 5 } // Span across all columns
                    };
                }
                return (
                    <div style={{ maxHeight: '400px', overflow: 'auto' }}>
                        {text}
                    </div>
                );
            }
        },
        {
            title: 'Day in the Life',
            dataIndex: 'role_description',
            key: 'role_description',
            render: (text, record) => {
                // Leave empty for the business area row and show role description for role rows
                if (record.isArea) {
                    return {
                        children: null, // Leave empty for the business area row
                        props: { colSpan: 0 } // Do not occupy any space in the description column
                    };
                }
                return (
                    <div style={{ maxHeight: '400px', overflow: 'auto' }}>
                        {text}
                    </div>
                );
            }
        },
        {
            title: 'Jobs to be Done',
            dataIndex: 'jobs_to_be_done',
            width: 400,
            key: 'jobs_to_be_done',
            render: (text, record) => {
                // Leave empty for the business area row and show jobs for role rows
                if (record.isArea) {
                    return {
                        children: null, // Leave empty for the business area row
                        props: { colSpan: 0 } // Do not occupy any space in the jobs column
                    };
                }
                return (
                    <div style={{ maxHeight: '400px', overflow: 'auto' }}>
                        {text}
                    </div>
                );
            }
        },
        {
            title: 'Pain Points',
            dataIndex: 'pain_points',
            key: 'pain_points',
            width: 200,
            render: (text, record) => {
                // Show pain points for roles (not for business area rows)
                if (record.isArea) {
                    return {
                        children: null, // Leave empty for business area row
                        props: { colSpan: 0 } // Do not occupy space for business area rows
                    };
                }
                return (
                    <div style={{ maxHeight: '400px', overflow: 'auto' }}>
                        {text}
                    </div>
                );
            }
        },
        {
            title: 'Ideas',
            dataIndex: 'ideas',
            key: 'ideas',
            width: 200,
            render: (text, record) => {
                // Show ideas for roles (not for business area rows)
                if (record.isArea) {
                    return {
                        children: null, // Leave empty for business area row
                        props: { colSpan: 0 } // Do not occupy space for business area rows
                    };
                }
                return (
                    <div style={{ maxHeight: '400px', overflow: 'auto' }}>
                        {text}
                    </div>
                );
            }
        }
    ];

    return (
        <div className='h-screen flex flex-col font-outfit'>
            <Header title="Report Viewer" />
            <div className='flex-1 overflow-y-auto px-40 mt-6'>
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    bordered
                    size="middle"
                    className='mb-10'
                />
            </div>
        </div>
    );
};

export default ReportViewer;