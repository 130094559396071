import React, { useEffect } from "react";
import "./Header.css";
import PathfindrLogo from "../../assets/icons/pathfindr.png";
import Menu from "../../assets/icons/menu.svg";
import { SettingOutlined } from '@ant-design/icons';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearInput } from "../../utils/setLoader";
import { Dropdown, Switch } from 'antd';
import { setChatToggle } from "../../redux/slices/chatbotStatesSlice";

const Header = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const chatToggle = useSelector((state) => state.chatbotStates.chatToggle)

  const handleRefresh = () => {
    clearInput({ dispatch })
    navigate('/')
    //window.location.reload()
  }

  const onChange = (checked) => {
    dispatch(setChatToggle({ chatToggle: !chatToggle }));
    if (checked == true) {
      navigate('/')
    }
  };

  

  const items = [
    {
      label: <div className="flex items-center gap-2 font-outfit">
        Chat
        <Switch size="small" checked={chatToggle} onChange={onChange} />
      </div>,
      key: '0'
    },
    {
      label: <>
        <Link className="font-outfit" to="/settings">
          Settings
          {/* <SettingOutlined />  */}
        </Link>
      </>,
      key: '1',
    }

  ];

  return (
    <div className="flex pt-6 px-6 text-textColor font-outfit bg-offWhite">
      <div
        className={`header-left-wrapper flex items-center w-full transition-all duration-300 justify-between`}
      >
        {location.pathname == "/settings" ? (
          <Link to='/'>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAhklEQVR4nO3UMQoCQQxG4a/1CGJra78X2HYPYKU30APodfRmCwpWFiroijCCiI2QdPMg7ftDJhkqlSyW2GGUIZ/jjhvG0fIWVzywiJY3OGPAKlo+w6nIN9HyKfoiH0pQRB3RZQYc3gHfI9pKovl45HVWSItL1pr+OrSJJF7d77O+ikrFXzwB0kBAkbaISu8AAAAASUVORK5CYII=" className="cursor-pointer" style={{width:"20px", height: "20px"}}/>
          </Link>
        ) : (
          <>
            <span>
              <img src={PathfindrLogo} className="cursor-pointer" style={{ width: "170px", height: "48px" }} onClick={() => handleRefresh()} />
            </span>
            <Dropdown
              menu={{
                items,
              }}
              trigger={['click']}
            >
              <span>
                <img src={Menu} style={{ width: "20px", height: "20px" }} className="cursor-pointer" />
              </span>
            </Dropdown>
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
