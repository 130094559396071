// Imports
import { Layout } from "antd";
import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./ChatBot.css";
import Header from "../Header/Header";
import Messages from "../Messages/Messages";
import InputSection from "../Input/InputSection";
import Suggestions from "../Suggestions/Suggestions";
import {
  selectSocketConnected,
  initializeSocket,
  disconnectSocket,
  getSocket
} from "../../redux/slices/socketioSlice";
import {
  setCurrentMessage,
  setCurrentResponse,
} from "../../redux/slices/chatbotStatesSlice";
import { setInputLoader } from "../../redux/slices/loaderSlice";
import ReportGenerator from "../ReportGenerator/ReportGenerator";
// Imports End

export const ChatBot = () => {
  /*  Initialize States */
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [streamMessage, setStreamMessage] = useState("");
  const [roomId, setRoomId] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(true);
  const chatWindowRef = useRef(null);
  const chatToggle = useSelector((state) => state.chatbotStates.chatToggle)

  const dispatch = useDispatch();


  /* Socket Initialization and Cleanup */
  useEffect(() => {
    dispatch(initializeSocket());

    return () => {
      dispatch(disconnectSocket());
    };
  }, [dispatch]);

  useEffect(() => {
    const socket = getSocket();
    if (!socket) return;

    const handleStream = (msg) => {
      if (msg.isFinal) {
        setMessages((prevMessages) => [
          ...prevMessages,
          { msg: streamMessage + msg.data, sender: "bot" },
        ]);

        setStreamMessage("");

        dispatch(
          setCurrentResponse({ currentResponse: streamMessage + msg.data })
        );

        dispatch(setInputLoader({ inputLoader: false }));
      } else {

        setStreamMessage((prevStreamMessage) => prevStreamMessage + msg.data);

      }
    };

    socket.on("room_assigned", (data) => {
      console.log("Room assigned:", data.room_id);
      setRoomId(data.room_id);
    });

    socket.on("stream", handleStream);

    // Cleanup on component unmount
    return () => {
      socket.off("room_assigned");
      socket.off("stream", handleStream);
    };
  }, [streamMessage]);

  /* Handles SendMessage */
  const sendMessage = (msg) => {
    setLoading(true);
    dispatch(setInputLoader({ inputLoader: true })); // Used to disable the Input

    setMessages((prevMessages) => [
      ...prevMessages,
      { msg: msg, sender: "User" },
    ]);

    const socket = getSocket();
    if (socket) {
      socket.emit("start_stream", { query_text: msg });
    }
    dispatch(setCurrentMessage({ currentMessage: msg }));
    setLoading(false);
    setInputValue("");
    setShowSuggestions(false);  // Hide suggestions after first message
  };

  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion);
    sendMessage(suggestion);
  };

  return (
    <Layout className={`flex flex-col h-[100vh] bg-offWhite ${chatToggle ? 'chat-layout' : 'report-layout'}`}>
      <Header />
      {chatToggle ? (
        <div className="content-container flex-grow flex flex-col overflow-hidden">
          <div
            ref={chatWindowRef}
            className={`flex-grow overflow-y-auto px-4 ${messages.length === 0 ? 'flex items-center justify-center' : ''
              }`}
          >
            <div className={`max-w-3xl mx-auto ${messages.length === 0 ? 'w-full' : ''}`}>
              <Messages
                messages={messages}
                chatWindowRef={chatWindowRef}
                loading={loading}
                streamMessage={streamMessage}
              />
            </div>
          </div>
          <div className="w-full max-w-2xl mx-auto pb-12">
            {showSuggestions && <Suggestions onSuggestionClick={handleSuggestionClick} />}
            <InputSection
              sendMessage={sendMessage}
              inputValue={inputValue}
              setInputValue={setInputValue}
            />
          </div>
        </div>
      ) : <ReportGenerator />
      
      
      }
    </Layout>
  );
};
