import React, { useState, useEffect } from "react";
import { Layout, Menu, Tooltip } from "antd";
import { useSelector, useDispatch } from "react-redux";
import "./SettingsSider.css";
import { setSettingIndex } from "../../../redux/slices/menuSlice";
import BulkUpload from "../../../components/BulkUpload/BulkUpload";
import backinactive from "../../../assets/icons/back-inactive.png"
import { Link } from "react-router-dom";

const { Sider } = Layout;

const SettingsSider = () => {
  const settingsIndex = useSelector((state) => state.menu.settingsIndex);
  const dispatch = useDispatch();

  // Initializing Menu Labels
  const menuItems = [
    { label: "Training", enabled: true },
    { label: "Analytics", enabled: false },
    { label: "Chat History", enabled: false }
  ];
  return (
    <>
      <Sider
        className="settings-sider-wrapper z-200"
        width={220}
        breakpoint="lg"
      >
        <div className="sider-header mt-8 font-outfit font-medium text-lg md:text-lg xl:text-lg pl-10 flex flex-col gap-8">
         <Link to="/">
           <img src={backinactive} alt="" style={{width: "24px"}} className="cursor-pointer" />
         </Link>
        <span>Settings</span>
        </div>
        <div className="sider-content pl-10">
          <Menu
            className="bg-transparent pl-0 text-sm font-outfit"
            mode="inline"
            defaultSelectedKeys={["1"]}
            selectedKeys={[`item-${settingsIndex}`]}
          >
            {menuItems.map((item, index) => (
              <Tooltip
                title={!item.enabled ? "Coming Soon" : ""}
                placement="right"
                key={`item-${index}`}
              >
                <Menu.Item
                  key={`item-${index}`}
                  onClick={() => {
                    if (item.enabled) dispatch(setSettingIndex(index));
                  }}
                  disabled={!item.enabled}
                >
                  <div className="flex items-center">
                    <div className="w-2 mr-2">
                      {settingsIndex === index && (
                        <div className="h-2 bg-blueBg rounded-full"></div>
                      )}
                    </div>
                    <div className="font-outfit">{item.label}</div>
                  </div>
                </Menu.Item>
              </Tooltip>
            ))}
          </Menu>
        </div>
      </Sider>
      {settingsIndex === 0 && <BulkUpload />} 
    </>
  );
};

export default SettingsSider;