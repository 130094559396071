import React from 'react';
import { useState, useEffect } from 'react';
import { message, Upload } from "antd";
import UploadButton from '../BulkUpload/UploadButton/UploadButton';
import { useDispatch } from 'react-redux';
import { getSocket } from '../../redux/slices/socketioSlice';
import { setProgress, setProgressMessage } from '../../redux/slices/loaderSlice';
import { useNavigate } from 'react-router-dom';


const { Dragger } = Upload;

const Uploader = ({data, setLoading}) => {
    const socket = getSocket()
    const [file, setFile] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const props = {
        name: "file",
        multiple: false,
        accept: ".csv",
        beforeUpload: (file) => {
            const isCSV = file.type === 'text/csv';
            if (!isCSV) {
                message.error(`${file.name} is not a csv file`);
            }
            return isCSV || Upload.LIST_IGNORE;
        },
        onChange(info) {

            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
                setFile(info.file);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
                setFile(null);
            }
        },
        customRequest: ({ file, onSuccess, onError }) => {
            // Simulate an upload delay
            setTimeout(() => {
                // This is a placeholder for actual upload logic.
                onSuccess("ok", { ...file, status: "done" }); // Ensure the file is marked as done on success.
            }, 500); // Increased delay for realism in simulation
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },

    };

    // Function to handle file upload to dummy endpoint
    const handleUpload = () => {
        setLoading(true)
        try {
            dispatch(setProgress({ progress: 30 }));
            dispatch(
                setProgressMessage({ progressMessage: "Files are being uploaded..." })
            );
            const fileData = {
                filename: file.originFileObj.name,
                content: file.originFileObj,
            }

            if (socket) {
                socket.emit('upload', { data: fileData });
            }
            else {
                throw new Error(
                    "Socket is not connected or emit function is not available."
                );
            }
        } catch (error) {
            console.error("Error during file upload:", error);
            dispatch(
                setProgressMessage({
                    progressMessage: "File upload failed. Please try again.",
                })
            );
        }
    };

    useEffect(() => {
        if (!socket) return;

        const handleUploadResponse = (data) => {
            if (data.isFinal) {
                if (
                    typeof data.data === "string" &&
                    (data.data.includes("Error"))
                ) {
                    message.error(data.data);
                    setLoading(false);
                    dispatch(setProgress({ progress: 0 }));
                } else {
                    console.log('here', data)
                    //setData(data.insights);
                    setLoading(false);
                    dispatch(setProgress({ progress: 100 }));
                    dispatch(
                        setProgressMessage({ progressMessage: data.data })
                    );
                    //message.success("Files uploaded successfully");
                    //console.log("success");
                    
                    navigate('/report', {state: {insights: data.insights}})
                }
            } else {
                switch (data.step) {
                    case 1:
                        setTimeout(() => {
                            dispatch(setProgress({ progress: 50 }));
                            dispatch(setProgressMessage({ progressMessage: data.data }));
                        }, 5000);
                        break;
                    case 2:
                        setTimeout(() => {
                            dispatch(setProgress({ progress: 70 }));
                            dispatch(setProgressMessage({ progressMessage: data.data }));
                        }, 3000);
                        break;
                    case 3:
                        setTimeout(() => {
                            dispatch(setProgress({ progress: 100 }));
                            dispatch(setProgressMessage({ progressMessage: data.data }));
                        }, 3000);
                        break;
                    default:
                        console.error("Unexpected step in upload response");
                        message.error(
                            "An unexpected error occurred during file processing."
                        );
                        setLoading(false);
                        break;
                }
            }
        };

        socket.on("upload_response", handleUploadResponse);

        return () => {
            socket.off("upload_response", handleUploadResponse);
        };
    }, [socket]);


    return (
        <>
            <div className="w-full flex flex-col justify-center pb-24 gap-10">
                <div className="relative flex w-[100%] justify-center gap-4">
                    <Dragger className="main-uploader w-[40%]" {...props}>
                        <p className="ant-upload-drag-icon flex justify-center">
                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC4ElEQVR4nO2Zz4uOURTHP0Kh7BjJykZ+ZGFjLQmRjIXCAgtLC1lbKLJUrFiI0LCUf2DiIYpGTNNQSiwQMig2mKOnzlu3J1LPr3PPzPnU7X3n3HPf99u3937vM88DQRAEQRAEQRD0jmQ0JoHlOEMyG+5MFB3WuDVRMjNwPHkdwrGB94HCQMeQNxPlHwaW5t010uHKRMlsCw9YCjzzkImSqYFuTJTMMrBK9ttZMsxAVyZKxlvYxXYWJwZma6JknoHZb2dxkIFZmyiZbeE6YzwH4dY0vYtjLtw6A+uSrYFFzxk44wz0grl+sRbgXb9EBnZjYBEZ2MxAL5jrF2sB3vVLZGA3BhaRgc0M9IK5frEW4F2/RAZ2Y2ARGdjMQC+Y6xdrAbM1A9cDt4EvOkaBTZWexcA54DXwA3gC7Ne58oHQb/3uXcmaYa19AuY10J91Bq4Bvum60pxHwLQasj3pu6Y9Y8Al4KP27dD5ezpfmjzgvNauNNTfG1JDwA1dMwLM1doRrT1M+qa0Nnj8uBG4COzTv4/r/ESyZkJrwx3qbxWpIeC9rlmd1OYDe4E9Se2p9j0HTgLbgAXJ/Mrk+1eo0eUv9DuwqEP95hn4U9cs/M9nb1Dz0oc/n4GdSc+Y1g8CB/T9rRb0Z52B73RNmYUDyq28Bdhc6Z2jRh4FHui6V8n8Ca1d1Zws3x9uQX9vSA0B13XNzeSkPKS1x5WtPp1s9WXaUx4mA9Zq7a0eSL+AJR3rbxWpIWAV8FXXvUlO4XLsTvrOas8H4HJyQFyofN5komO0B/1ZXAeu06ya0uvAO8DWSk95YJwCXuh14Evg9F+y80yi41hL+ntD4n/hbgz0grl+sRbgXb/E/cBuDCzifmAzA71grl+sBXjXL5GB3RhYRAY2M9AL5vrFWoB3/RIZ2I2BRWRgMwO9YK5frAV41y+Rgd0YWEQGNjPQC+b6xVqAd/0yQ0YYiFMDgyAIgiAIgoDZyB+BoWitzlM2eAAAAABJRU5ErkJggg==" style={{ width: "58px" }} />
                        </p>
                        <p className="ant-upload-text font-outfit ">
                            Click or drag file to this area to upload
                        </p>
                        <p className="ant-upload-hint font-outfit">
                            Support for a single upload. Strictly prohibited from
                            uploading company data or other banned files.
                        </p>
                    </Dragger>
                </div>
                <div className='mx-auto'>
                    <UploadButton
                        onClick={handleUpload}
                        label={"Spark AI"} />
                </div>
            </div>

        </>
    )
}

export default Uploader