// Imports
import {Button, Input, Modal} from "antd";
import React, {useEffect, useState} from "react";
import {verifyCode} from "../../api/VerifyCode/verifyCode";
import {LockOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {setCode, setVerified} from "../../redux/slices/codeSlice";
import {toast} from "react-toastify";
import PathfindrLogo from "../../assets/icons/pathfindr.png";
import './CodeModal.css'
// Imports End

export const CodeModal = () => {

    const [loading, setLoading] = useState(false);
    const verified = useSelector((state) => state.code.isVerified);
    const [isModalOpen, setIsModalOpen] = React.useState(!verified);
    const inputRef = React.useRef();
    const [message, setMessage] = useState("");

    const dispatch = useDispatch();

    useEffect(() => {
        if (verified) {
            setIsModalOpen(false);
        } else {
            setIsModalOpen(true);
        }
    }, [verified]);

    return (
        <Modal centered={true} closable={false} title="Access Code" open={isModalOpen} footer={
            <>
                {
                    message && <div
                        className="text-red-600 border-[1px] border-red-600 w-full bg-red-200 rounded-md mb-2 py-2 text-center">
                        {message}
                    </div>
                }
                <Button key="submit" style={{
                    backgroundColor: "#1C46F3",
                    color: "white",
                    width: "100%",
                    height: "40px",
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "24px",
                    letterSpacing: "0.1em",
                    border: "none"
                }} loading={loading} onClick={async () => {
                    setLoading(true);
                    try {
                        setMessage("");
                        await new Promise((resolve) => setTimeout(resolve, 1000));
                        await verifyCode(inputRef.current.input.value);
                        setLoading(false);
                        setIsModalOpen(false)
                        dispatch(setVerified({isVerified: true}));
                        toast.success("Code Verified Successfully");
                    } catch (error) {
                        setMessage('Invalid Code. Please Try Again');
                        setLoading(false);
                    }
                }}>
                    Verify
                </Button>
                <div className="flex w-full items-end justify-end mt-5">
                    <div
                        className="flex flex-col items-center justify-center  mb-4 text-gray-500">
                        <p className="mr-2">Powered by</p>
                        <img src={PathfindrLogo} className="cursor-pointer" alt="Pathfindr"
                             style={{width: "150px", height: "40px"}}/>
                    </div>
                </div>

            </>
        }>
            <div>
                Please enter the access code provided by <a className='text-[#1C46F3]' target='_blank' href='https://www.pathfindr.ai'>Pathfindr.ai </a>
                to proceed further. If you do not have an access
                code, kindly contact <a className='text-[#1C46F3]' target='_blank' href='https://www.pathfindr.ai'>Pathfindr.ai</a> for assistance in obtaining one
            </div>
            <Input rootClassName="mt-5" size="large" placeholder="Enter Access Code" ref={inputRef}
                   prefix={<LockOutlined/>}/>
        </Modal>
    );

};
